import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Flex, FormControl, Grid, Input, InputGroup, Text } from "@chakra-ui/react";
import { isEqual } from "lodash";

import BrandProductsSelect from "../../components/BrandProductsSelect";
import InputWithSymbol from "../../components/InputWithSymbol";

import { handleEnterKey } from "../../utils/blurOnEnter";

import { addingPromocode, editingPromocode, fetchPromocode } from "./store/Promocodes.thunk";
import { getPromocodeState, getPromocodes } from "./store/Promocodes.selectors";
import { transformOldPromocode, transformPromocodeForSend } from "./utils/transformData";
import { PROMOCODE_VALIDATION_SCHEMA } from "./constants";
import { createPromoCodeMessage } from "./utils/createPromoCodeMessage";
import { onlyNumberAndLetters } from "../../utils/regexp";
import { checkNaN } from "../../utils/checkNaN";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";
import { Drawer } from "../../components/Drawer/Drawer";
import { useNavigate, useParams } from "react-router-dom";
import { SubCategoriesSelect } from "../CashbackSettings/SubCategoriesSelect";
import { useFormik } from "formik";
import { CategoriesSelect } from "../../components/CategoriesSelect/CategoriesSelect";
import { RoleSelect } from "../../components/RoleSelect/RoleSelect";
import { LoadingState } from "../../store/common";
import { usePrompt } from "../../hooks/usePrompt";
import { getRoleObj } from "./utils/getRoleObj";
import BrandsSelect from "../../components/BrandsSelect";
import { DatePicker } from "../../components/DatePicker/DatePicker";
import { transformDate } from "./utils/convertedDate";
import { datePickerConfig } from "./config";

const initialValues = {
  name: "",
  promocode: "",
  percent: 0,
  availableFor: null,
  category: null,
  subcategory: null,
  brand: null,
  brands: [],
  products: [],
  startDate: "",
  endDate: "",
  isReusable: false,
};

const EditPromocodeModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const promocodes = useSelector(getPromocodes());
  const { loadingState, promocodeDetails: promocode } = useSelector(getPromocodeState);

  const handleSavePromocode = (values) => {
    if (id) {
      if (isEqual(values, promocode)) {
        navigate(-1);
        return;
      }

      dispatch(editingPromocode({ id, objToSend: transformPromocodeForSend(values) }));
    } else {
      dispatch(addingPromocode(transformPromocodeForSend(values)));
    }

    navigate(-1);
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: handleSavePromocode,
    validationSchema: PROMOCODE_VALIDATION_SCHEMA(promocodes),
  });

  const handleChangeDiscount = (value) => {
    try {
      const discountNum = Number(value);

      if (checkNaN(discountNum)) {
        return;
      }

      if (discountNum >= 0 && discountNum <= 99) {
        formik.setFieldValue("percent", discountNum);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteProducts = (i) => {
    formik.setFieldValue(
      "products",
      formik.values.products.filter(({ id: stId }) => stId !== i)
    );
  };

  const handleDeleteBrands = useCallback(
    (i) => {
      formik.setFieldValue(
        "brands",
        formik.values.brands.filter(({ id: stId }) => stId !== i)
      );
    },
    [formik]
  );

  const handleAddBrands = useCallback(
    (brand) => {
      formik.setFieldValue("brands", [...formik.values.brands, brand]);
    },
    [formik]
  );

  const handleChangeCategory = (value) => {
    formik.setFieldValue("subcategory", null);
    formik.setFieldValue("category", value);
  };

  const handleChangeRole = (value) => formik.setFieldValue("availableFor", value);

  const handleReusablePromo = () => {
    formik.setFieldValue("isReusable", !formik.values.isReusable);
  };

  const handlePromocode = (e) => {
    if (!e.target.value.match(onlyNumberAndLetters)) {
      return;
    }

    formik.setFieldValue("promocode", e.target.value.toUpperCase());
  };

  useEffect(() => {
    if (promocode && id === promocode.id) {
      formik.resetForm({
        values: {
          ...promocode,
          startDate: transformDate(promocode.startDate),
          endDate: transformDate(promocode.endDate),
          availableFor: getRoleObj(promocode.availableFor),
          category: promocode.subcategory?.catalog_product ?? null,
        },
      });
    }
  }, [id, promocode]);

  useEffect(() => {
    if (id) {
      dispatch(fetchPromocode(id));
    }
  }, [id]);

  const { values, errors, setFieldValue, handleChange, handleSubmit, dirty } = formik;
  usePrompt(!formik.isSubmitting && dirty);
  return (
    <Drawer
      isOpen
      isError={loadingState === LoadingState.REJECTED}
      isLoading={loadingState}
      handleClose={() => navigate(-1)}
      handleSave={handleSubmit}
      extraText={createPromoCodeMessage(values.brands, values.subcategory, values.products)}
    >
      <FormControlWithError hasError={!!errors?.name} errorText={errors?.name}>
        <Text>Заголовок*</Text>
        <Input autoComplete="off" name="name" value={values.name} onChange={handleChange} onKeyDown={handleEnterKey} />
      </FormControlWithError>

      <FormControlWithError hasError={!!errors?.promocode} errorText={errors?.promocode}>
        <Text>Промокод*</Text>
        <Input
          autoComplete="off"
          id="promocode"
          name="promocode"
          value={values.promocode}
          onChange={handlePromocode}
          onKeyDown={handleEnterKey}
        />
      </FormControlWithError>

      <FormControlWithError hasError={!!errors?.percent} errorText={errors?.percent}>
        <Text>Скидка*</Text>
        <InputGroup>
          <InputWithSymbol
            name="percent"
            value={values.percent || ""}
            symbol="%"
            error={errors?.percent}
            onChange={handleChangeDiscount}
            onKeyDown={handleEnterKey}
          />
        </InputGroup>
      </FormControlWithError>

      <FormControlWithError hasError={!!errors?.availableFor} errorText={errors?.availableFor}>
        <Text>Доступен для</Text>
        <RoleSelect value={values.availableFor} placeholder="Выберите доступность..." onChange={handleChangeRole} />
      </FormControlWithError>

      <Checkbox marginBottom={5} isChecked={values.isReusable} onChange={handleReusablePromo}>
        <Text>Промокод многразовый</Text>
      </Checkbox>

      <Grid templateColumns="repeat(2,1fr)" sx={{ gap: "15px" }}>
        <FormControlWithError errorText={errors.startDate} hasError={errors?.startDate}>
          <Text>Дата начала действия</Text>
          <InputGroup>
            <DatePicker
              error={errors?.startDate}
              selected={values.startDate}
              onChange={(startDate) => formik.setFieldValue("startDate", startDate)}
              minDate={new Date()}
              {...datePickerConfig}
            />
          </InputGroup>
        </FormControlWithError>

        <FormControlWithError errorText={errors.endDate} hasError={errors?.endDate}>
          <Text>Дата окончания действия</Text>
          <InputGroup>
            <DatePicker
              error={errors?.endDate}
              minDate={values.startDate ? values.startDate : new Date()}
              onChange={(endDate) => formik.setFieldValue("endDate", endDate)}
              selected={values.endDate}
              {...datePickerConfig}
            />
          </InputGroup>
        </FormControlWithError>
      </Grid>

      <Grid templateColumns="repeat(2,1fr)" sx={{ gap: "15px" }}>
        <FormControl>
          <Text>Категория</Text>
          <CategoriesSelect
            value={values.category}
            placeholder="Выберите категорию..."
            onChange={handleChangeCategory}
          />
        </FormControl>

        <FormControlWithError errorText={errors.subcategory} hasError={errors?.subcategory}>
          <Text>Подкатегория</Text>
          <SubCategoriesSelect
            isDisabled={!values.category?.id}
            value={values.subcategory}
            onChange={(subCategory) => setFieldValue("subcategory", subCategory)}
            categoryId={values.category?.id}
          />
        </FormControlWithError>
      </Grid>

      <Text>Бренды</Text>
      <BrandsSelect brands={values.brands} onAdd={handleAddBrands} onDelete={handleDeleteBrands} />

      <Text>Товары</Text>
      <BrandProductsSelect
        isSearchAll
        brands={values.brands}
        subcategory={values.subcategory}
        products={values.products}
        onAdd={(product) => formik.setFieldValue("products", [...formik.values.products, product])}
        onDelete={handleDeleteProducts}
      />
    </Drawer>
  );
};

export default EditPromocodeModal;
