import { useSelector, useDispatch } from "react-redux";
import Autocomplete from "../Autocomplete";
import { citiesIsUpdated, getCities } from "../../pages/Cities/store/Cities/Cities.selectors";
import { useEffect } from "react";
import { getAllCities } from "../../pages/Cities/store/Cities/Cities.thunk";

export const CitiesAutocomplete = ({ value, handleChange, isAllOptionsNeed = false, ...props }) => {
  const dispatch = useDispatch();

  const cities = useSelector(getCities())?.data || [];
  const isCitiesNeedUpdate = useSelector(citiesIsUpdated());
  useEffect(() => {
    if (isCitiesNeedUpdate) dispatch(getAllCities());
  }, [isCitiesNeedUpdate, dispatch]);

  return (
    <Autocomplete
      items={isAllOptionsNeed ? [{ name: "Все города", id: "all" }, ...cities] : cities}
      value={value}
      onChange={handleChange}
      {...props}
    />
  );
};
