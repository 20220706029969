const ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",
  NOTFOUND: "*",
  // pages
  BANNERS: "/banners",
  BRANDS: "/brands",
  CATEGORY: "/categories",
  CITIES: "/cities",
  PRODUCTS: "/products",
  PROMOCODES: "/promocode",
  SPECIALIST_PROMOCODES: "specialist-promocodes",
  PROTOCOL_CATEGORIES: "/protocol-categories",
  PROTOCOLS: "/protocols",
  ORDERS: "/orders",
  SEMINARS: "/seminars",
  USERS: "/users",
  WISHLIST: "/wishlist",
  REVIEW: "/reviews",
  CLIENTS: "/clients",
  CLIENT_INFO: "/clients/:id",
  SETTINGS: "/settings",
  SETTINGS_STORIES: "/settings/stories",
  SETTINGS_CASHBACK: "/settings/cashback",
  SETTINGS_PROMOTION: "/settings/promotion",
  SETTINGS_EMPLOYEES: "/settings/employees",
  SETTINGS_NOTIFICATIONS: "/settings/notifications",
  SETTINGS_API_GREEN_NOTIFICATIONS: "/settings/api-green-notifications",

  SETTINGS_MY_CARD: "/settings/my-card",
};

export default ROUTES;
