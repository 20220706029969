import { isValidPhoneNumber } from "libphonenumber-js";
import * as Yup from "yup";
import { validationUnique } from "../../services/employeeService";
import { checkIsRoleManager } from "../../utils/roles";

Yup.addMethod(Yup.object, "optional", function (isOptional = true, defaultValue = undefined) {
  return this.transform((value) => {
    if (!isOptional) return value;
    if (value && Object.values(value).some((v) => !!v)) {
      return value;
    }

    return defaultValue;
  }).default(defaultValue);
});

export const PHONE_VALIDATION = Yup.string().test("isValidPhone", "Некорректный номер телефона", (phone) =>
  phone?.length > 0 ? isValidPhoneNumber(phone.at() === "+" ? phone : `+${phone}`) : true
);

const PASSWORD_ERROR_MESSAGE = "Пароль должен содержать минимум 1 цифру, 1 заглавную и одну строчную латинскую букву";

export const PASSWORD_VALIDATOR = Yup.string()
  .min(6, "Минимальная длина пароля 6 символов")
  .matches(/(?=.*[0-9])/, PASSWORD_ERROR_MESSAGE)
  .matches(/(?=.*[A-Z])/, PASSWORD_ERROR_MESSAGE)
  .matches(/(?=.*[a-z])/, PASSWORD_ERROR_MESSAGE);

export const EMAIL_VALIDATOR = Yup.string().trim().email("Некорректный адрес электронной почты");

export const EMPLOYEE_VALIDATION_SCHEMA = (employees, isCurrentUser, oldPhone, oldEmail) =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Некорректный адрес электронной почты")
      .required('Поле "Почта" обязательное')
      .max(50, "Почта должна быть максимум 50 символов")
      .test({
        name: "isUniq",
        test: async (field, { createError }) => {
          try {
            if (oldEmail !== field.trim()) {
              await validationUnique({ email: field.trim() });
            }

            return true;
          } catch (error) {
            return createError({ message: error.response?.data?.message ?? "Пользователь с такой почтой существует" });
          }
        },
      }),
    name: Yup.string().trim().required('Поле "Имя" обязательное').max(50, "Имя должно содержать максимум 50 символов"),
    lastName: Yup.string()
      .trim()
      .required('Поле "Фамилия" обязательное')
      .max(50, "Фамилия должна содержать максимум 50 символов"),
    role: Yup.object().required('Поле "Должность" обязательное').nullable(),
    phone: PHONE_VALIDATION.required('Поле "Телефон" обязательное').test({
      message: "Номер телефона уже занят",
      name: "isValid",
      test: async (field) => {
        try {
          if (oldPhone !== field.trim()) {
            await validationUnique({ phone: field.trim() });
          }

          return true;
        } catch (error) {
          return false;
        }
      },
    }),
    warehouses: Yup.array()
      .when("role", {
        is: (role) => checkIsRoleManager(role?.value),
        then: Yup.array().min(1, 'Поле "Город" обязательно').required('Поле "Город" обязательно'),
        otherwise: Yup.array().nullable(),
      })
      .nullable(),
    passwords: Yup.object()
      .shape({
        password: isCurrentUser ? PASSWORD_VALIDATOR : Yup.string().trim(),
        repeatPassword: isCurrentUser
          ? Yup.string().oneOf([Yup.ref("password"), null], "Пароли должны совпадать")
          : Yup.string().trim(),
      })
      .optional(),
  });
