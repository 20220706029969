import React, { useState } from "react";
import { Box, Select, Tag, TagCloseButton, TagLabel, Wrap, WrapItem, Button, Text } from "@chakra-ui/react";

export const CitiesMultiselect = ({ values, isDisabled = false, handleChange, cities }) => {
  const [selectedCities, setSelectedCities] = useState(values);

  const handleSelectChange = (event) => {
    const value = event?.target.value;
    if (!selectedCities) {
      const currentCity = cities.find((city) => city.id === value);
      setSelectedCities([currentCity]);
      handleChange([currentCity]);

      return;
    }

    if (value && !selectedCities.some((city) => city.id === value)) {
      const currentCity = cities.find((city) => city.id === value);
      const updateCities = [...selectedCities, currentCity];
      setSelectedCities(updateCities);
      handleChange(updateCities);
    }
  };

  const handleTagClose = (cityId) => {
    const updateCities = selectedCities.filter((c) => c.id !== cityId);
    setSelectedCities(updateCities);
    handleChange(updateCities);
  };

  return (
    <Box className="card" p={5} borderWidth={1} borderRadius="lg">
      <Wrap spacing={2} mb={5}>
        {selectedCities?.map((city) => (
          <WrapItem key={city.id}>
            <Tag size="md" borderRadius="full" variant="subtle" colorScheme="purple">
              <TagLabel>{city.name}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose(city.id)} />
            </Tag>
          </WrapItem>
        ))}
      </Wrap>

      <Select fontSize="14px" placeholder="Выберите город" onChange={handleSelectChange} mb={4}>
        {!!cities?.length &&
          cities?.map((city) => (
            <option key={city.id} value={city.id}>
              {city.name}
            </option>
          ))}
      </Select>
    </Box>
  );
};
