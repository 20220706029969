export const RussianPost = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 106 106" fill="none">
      <rect width="106" height="106" rx="20.6117" fill="#1928DC" />
      <g clipPath="url(#clip0_2808_19969)">
        <path
          d="M24.0032 26.7135L24.0032 50.7492H19.9972L19.9972 30.1723H13.1815L13.1815 50.7492H9.1756L9.1756 26.7135L24.0032 26.7135Z"
          fill="white"
        />
        <path
          d="M31.8111 49.8042C30.611 48.7021 29.7383 47.2903 29.2889 45.7241C28.6311 43.5261 28.3182 41.2394 28.3616 38.9455C28.3191 36.5877 28.6317 34.237 29.2889 31.9722C29.7302 30.3545 30.6018 28.8868 31.8111 27.7252C32.8685 26.8183 34.2155 26.3198 35.6084 26.3198C37.0014 26.3198 38.3484 26.8183 39.4057 27.7252C40.6215 28.8763 41.4918 30.344 41.9187 31.9629C42.5768 34.2307 42.8894 36.5846 42.846 38.9455C42.8891 41.2424 42.5763 43.5322 41.9187 45.7334C41.4808 47.3002 40.6136 48.7135 39.415 49.8135C38.3368 50.6829 36.9935 51.157 35.6084 51.157C34.2234 51.157 32.8801 50.6829 31.8019 49.8135L31.8111 49.8042ZM32.7941 43.7211C32.9508 44.7693 33.3371 45.7699 33.9254 46.6514C34.1106 46.9289 34.3597 47.1579 34.6517 47.3193C34.9438 47.4806 35.2703 47.5696 35.6038 47.5787C36.596 47.5787 37.3749 46.8554 37.9499 45.4088C38.6152 43.3124 38.904 41.1148 38.803 38.9177C38.8285 37.2463 38.6981 35.5761 38.4135 33.9288C38.2639 32.8457 37.8811 31.808 37.2915 30.8873C37.126 30.5856 36.8825 30.3339 36.5864 30.1585C36.2903 29.9832 35.9525 29.8907 35.6084 29.8907C35.2643 29.8907 34.9265 29.9832 34.6305 30.1585C34.3344 30.3339 34.0908 30.5856 33.9254 30.8873C33.3428 31.809 32.9576 32.8415 32.7941 33.9195C32.5061 35.5633 32.3726 37.2305 32.3953 38.8992C32.37 40.5157 32.5035 42.1308 32.7941 43.7211Z"
          fill="white"
        />
        <path
          d="M55.3259 26.7135V35.9309H53.2024C52.4327 35.9731 51.6639 35.8333 50.9583 35.5229C50.4015 35.2273 49.9749 34.7346 49.7621 34.1412C49.4788 33.3156 49.35 32.4449 49.3819 31.5726V26.7135H45.376V31.554C45.2595 33.642 45.8834 35.7042 47.1379 37.3775C47.7966 38.1091 48.6131 38.6814 49.5256 39.0509C50.4381 39.4204 51.4227 39.5775 52.4049 39.5103H55.3259V50.7492H59.3319V26.7135L55.3259 26.7135Z"
          fill="white"
        />
        <path
          d="M77.0016 30.1723L72.3001 30.1723V50.7492H68.2942V30.1723H63.5928V26.7135L77.0016 26.7135V30.1723Z"
          fill="white"
        />
        <path
          d="M85.15 26.714L88.3584 26.714C89.6805 26.6425 90.9941 26.9653 92.1326 27.6413C93.1261 28.3052 93.8981 29.2515 94.3488 30.3583C94.9002 31.7313 95.247 33.1779 95.3781 34.6517L97.2327 50.7033H93.2268L91.9286 39.4922H85.1314V50.7496H81.1255V26.714H85.15ZM85.15 36.0797H91.5484L91.3815 34.698C91.3073 33.8102 91.1235 32.935 90.8343 32.0923C90.6315 31.5253 90.2338 31.0488 89.7123 30.7477C89.0397 30.4253 88.2964 30.2785 87.5517 30.3212H85.15V36.0797Z"
          fill="white"
        />
        <path
          d="M13.5896 55.5345C14.9252 55.4991 16.2435 55.8431 17.3915 56.5267C18.5171 57.2431 19.3946 58.2889 19.9045 59.5219C20.5559 61.0929 20.8718 62.7827 20.8318 64.4829C20.873 66.0911 20.5859 67.6909 19.9879 69.1844C19.545 70.3315 18.8288 71.3533 17.9015 72.161C17.2745 72.7517 16.4614 73.1058 15.6018 73.1625C15.1566 73.1937 14.7099 73.1268 14.2933 72.9666C13.8767 72.8064 13.5004 72.5567 13.1908 72.2352V79.5701H9.1756L9.1756 55.5345H13.5896ZM13.4134 67.9789C13.5122 68.4084 13.7161 68.8067 14.0068 69.138C14.1016 69.2545 14.22 69.3495 14.3543 69.4167C14.4887 69.4838 14.6357 69.5216 14.7858 69.5274C15.1378 69.5079 15.4692 69.3554 15.7131 69.1009C16.0876 68.7116 16.3558 68.2327 16.492 67.7099C16.7173 66.9109 16.8205 66.0824 16.798 65.2526C16.798 63.0085 16.5291 61.4228 15.9727 60.4955C15.7449 60.067 15.402 59.7106 14.9826 59.4665C14.5632 59.2224 14.0839 59.1002 13.5988 59.1138H13.1908L13.1908 66.1243C13.1785 66.7498 13.2534 67.374 13.4134 67.9789Z"
          fill="white"
        />
        <path
          d="M25.2551 78.6147C24.0497 77.5131 23.1762 76.0968 22.7328 74.5253C22.0744 72.3274 21.7616 70.0406 21.8055 67.7467C21.763 65.3919 22.0756 63.0442 22.7328 60.7826C23.1704 59.1661 24.0428 57.7004 25.2551 56.5449C26.3124 55.638 27.6594 55.1395 29.0524 55.1395C30.4454 55.1395 31.7923 55.638 32.8497 56.5449C34.0601 57.6966 34.9297 59.1597 35.3627 60.7734C36.02 63.0413 36.3326 65.3951 36.29 67.756C36.3495 70.0505 36.0523 72.3404 35.409 74.5438C34.9734 76.1117 34.1059 77.5255 32.9053 78.6239C31.83 79.4998 30.4856 79.978 29.0987 79.978C27.7119 79.978 26.3675 79.4998 25.2922 78.6239L25.2551 78.6147ZM26.2473 72.5408C26.4099 73.5629 26.7927 74.5375 27.3693 75.3969C27.5528 75.6719 27.7989 75.8994 28.0875 76.0606C28.3761 76.2219 28.6988 76.3122 29.0292 76.3242C30.0183 76.3242 30.7973 75.6009 31.366 74.1543C32.0405 72.0599 32.3326 69.8611 32.2284 67.6632C32.2536 65.9918 32.1233 64.3216 31.8389 62.6743C31.685 61.5981 31.3025 60.5673 30.7169 59.6513C30.5514 59.3496 30.3079 59.0979 30.0118 58.9226C29.7157 58.7473 29.3779 58.6548 29.0338 58.6548C28.6897 58.6548 28.352 58.7473 28.0559 58.9226C27.7598 59.0979 27.5162 59.3496 27.3508 59.6513C26.7671 60.6004 26.391 61.6622 26.2473 62.7671C25.9602 64.411 25.8267 66.078 25.8486 67.7467C25.8252 69.3539 25.9588 70.9596 26.2473 72.5408Z"
          fill="white"
        />
        <path
          d="M39.026 60.9685C40.0037 59.1575 41.5429 57.7127 43.4122 56.8513C45.6005 55.9011 47.9734 55.4513 50.3577 55.5346V59.1418C47.5016 59.1418 45.3224 59.8558 43.8109 61.3024C42.2994 62.749 41.539 64.891 41.539 67.7935C41.539 70.696 42.2901 72.6155 43.8016 73.9693C45.3131 75.3232 47.5108 76.0094 50.3577 76.0094V79.5702C46.259 79.5702 43.0969 78.5811 40.8714 76.6029C38.6458 74.6246 37.5331 71.6727 37.5331 67.7471C37.445 65.3973 37.9589 63.064 39.026 60.9685Z"
          fill="white"
        />
        <path
          d="M52.509 60.9679C53.4875 59.1541 55.0306 57.7087 56.9044 56.8507C59.0781 55.892 61.438 55.4295 63.8128 55.4968V59.104C60.966 59.104 58.7868 59.8181 57.266 61.2647C55.7453 62.7113 54.9942 64.8904 54.9942 67.7465C54.9942 70.6026 55.7453 72.5685 57.2568 73.9223C58.7683 75.2762 60.966 75.9624 63.8035 75.9624V79.5696C59.7048 79.5696 56.5427 78.5805 54.3172 76.6022C52.0917 74.624 50.9789 71.6721 50.9789 67.7465C50.9023 65.3935 51.429 63.0599 52.509 60.9679Z"
          fill="white"
        />
        <path
          d="M69.7005 55.5344V64.112L76.5069 58.372L80.5128 54.9966V79.5701H76.5069V63.2125L69.7005 68.9525V79.5701H65.6852V55.5344H69.7005Z"
          fill="white"
        />
        <path
          d="M86.4029 55.5344V64.1119L93.2093 58.3719L97.1967 55.0151V79.57H93.1908V63.2124L86.3844 68.9524V79.57H82.397V55.5344H86.4029Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2808_19969">
          <rect width="88.0381" height="53.6537" fill="white" transform="translate(9.1756 26.315)" />
        </clipPath>
      </defs>
    </svg>
  );
};
