import { Boxberry } from "../../../assets/icon/Boxberry";
import { CDEK } from "../../../assets/icon/CDEK";
import { Filara } from "../../../assets/icon/Filara";
import { FivePost } from "../../../assets/icon/FivePost";
import { RussianPost } from "../../../assets/icon/RussianPost";
import AutoPostLogo from "../../../assets/png/AutoPostLogo.webp";

export const DELIVERY_SERVICE_LIST = {
  FILARA: "FILARA",
  FIVE_POST: "FIVE_POST",
  BOXBERRY: "BOXBERRY",
  RUSSIAN_POST: "RUSSIAN_POST",
  CDEK: "CDEK",
  AUTOPOST: "AUTOPOST",
};

export const serviceDelivery = {
  [DELIVERY_SERVICE_LIST.FILARA]: "Filara",
  [DELIVERY_SERVICE_LIST.FIVE_POST]: "5 post",
  [DELIVERY_SERVICE_LIST.BOXBERRY]: "Boxberry",
  [DELIVERY_SERVICE_LIST.RUSSIAN_POST]: "Почта России",
  [DELIVERY_SERVICE_LIST.CDEK]: "CDEK",
  [DELIVERY_SERVICE_LIST.AUTOPOST]: "Автопочта",
};

export const getDeliveryIcon = (type) => {
  switch (type) {
    case DELIVERY_SERVICE_LIST.BOXBERRY:
      return <Boxberry />;
    case DELIVERY_SERVICE_LIST.FILARA:
      return <Filara />;
    case DELIVERY_SERVICE_LIST.CDEK:
      return <CDEK />;
    case DELIVERY_SERVICE_LIST.FIVE_POST:
      return <FivePost />;
    case DELIVERY_SERVICE_LIST.RUSSIAN_POST:
      return <RussianPost />;
    case DELIVERY_SERVICE_LIST.AUTOPOST:
      return <img src={AutoPostLogo} width={25} height={25} alt="autopost" />;
    default:
      return null;
  }
};
