import { useState, useRef } from "react";
import Downshift from "downshift";
import { matchSorter } from "match-sorter";
import { Box, IconButton, Input, InputGroup, InputRightElement, Tooltip } from "@chakra-ui/react";
import Option from "./Option/Option";
import { ControlDawn } from "../../assets/icon/ControlDawn";
import { CrossIcon } from "../../assets/icon/CrossIcon";
import { AUTOCOMPLETE_Z_INDEX, CROSS_ICON_Z_INDEX, TEXT_STYLE } from "../../common/constants";
import { useIsOverflow } from "../../hooks/hooks";

import { useScrollIntoViewError } from "../../hooks/useScrollIntoViewError";
import styled from "@emotion/styled";

const cancelButtonStyle = {
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  right: "30px",
  top: "3px",
  width: "35px",
  height: "35px",
  zIndex: CROSS_ICON_Z_INDEX,
};

export const InputOverflowText = styled(Input)`
  padding-right: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &&[readonly] {
    background-color: #fff;
  }

  &&[aria-invalid="true"][readonly] {
    border-color: #e53e3e;

    // Chakra use !important for this value
    box-shadow: 0 0 0 1px #e53e3e !important;
  }
`;

export const TooltipText = styled("span")`
  cursor: ${(props) => props.cursor};
  overflow: ${(props) => props.overflow};
  text-overflow: ${(props) => props.textOverflow};
`;

const Autocomplete = ({
  isDisabled,
  placeholder,
  items,
  value,
  error,
  onChange,
  isNeedToKeepInputEmpty = false,
  deleteButton = true,
}) => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState(value?.name ?? "");

  const ref = useRef(null);
  const [isOverflow] = useIsOverflow(ref);

  useScrollIntoViewError(ref, error);

  const closeList = () => {
    setOpen(false);
  };

  const onAutocompleteChange = (selectedItem) => {
    if (isNeedToKeepInputEmpty) {
      setSearchText("");
    }

    onChange(selectedItem);
    closeList();
  };

  const backgroundColor = isDisabled ? "#F8F8FA" : "transparent";

  const getItems = (itemValue) =>
    itemValue && itemValue !== value?.name ? matchSorter(items, itemValue, { keys: ["name"] }) : items;

  const itemToString = (item) => (item ? item.name : "");
  const handleDeleteItems = () => {
    onChange(null);
  };

  return (
    <Downshift
      onOuterClick={closeList}
      onChange={onAutocompleteChange}
      onSelect={onChange}
      selectedItem={value}
      itemToString={itemToString}
      inputValue={searchText}
      onInputValueChange={(text) => setSearchText(text)}
    >
      {({ getInputProps, getMenuProps, getItemProps, getToggleButtonProps, isOpen, highlightedIndex, inputValue }) => (
        <div style={{ position: "relative" }}>
          <InputGroup>
            <Tooltip
              label={inputValue || "-"}
              color="black"
              bg="white"
              placement="bottom-start"
              isDisabled={!isOverflow}
            >
              <InputOverflowText
                ref={ref}
                {...getInputProps()}
                placeholder={placeholder}
                isDisabled={isDisabled}
                isReadOnly={value}
                cursor={value ? "default" : "text"}
                value={inputValue}
                sx={{
                  backgroundColor,
                }}
                autoComplete="off"
              />
            </Tooltip>

            {(value || inputValue) && !isDisabled && deleteButton && (
              <Box
                onClick={() => {
                  if (!isDisabled) {
                    handleDeleteItems();
                    setSearchText("");
                  }
                }}
                cursor={isDisabled ? "not-allowed" : "pointer"}
                sx={cancelButtonStyle}
              >
                <CrossIcon />
              </Box>
            )}

            <InputRightElement>
              <IconButton
                isDisabled={isDisabled}
                icon={<ControlDawn />}
                style={{ backgroundColor: "transparent" }}
                {...getToggleButtonProps()}
                color={open ? "black" : "gray.300"}
              />
            </InputRightElement>
          </InputGroup>

          {isOpen && (
            <Box
              {...getMenuProps({
                style: {
                  maxHeight: "240px",
                  height: "fit-content",
                  background: "#FFFFFF",
                  padding: "4px 0px",
                  boxShadow: "0px 4px 8px rgba(76, 93, 112, 0.3), 0px 0px 1px rgba(76, 93, 112, 0.3)",
                  position: "absolute",
                  zIndex: AUTOCOMPLETE_Z_INDEX,
                  width: "100%",
                  overflowY: "scroll",
                  ...TEXT_STYLE,
                },
              })}
            >
              <Box variant="simple" maxHeight="200px">
                {isOpen &&
                  getItems(inputValue).map((item, index) => (
                    <div
                      key={index}
                      {...getItemProps({
                        item,
                        key: item.id,
                        style: {
                          backgroundColor: index === highlightedIndex ? "#F7ECF8" : null,
                          paddingLeft: "20px",
                          paddingTop: "5px",
                          cursor: "default",
                        },
                      })}
                    >
                      <Option name={item.name} />
                    </div>
                  ))}
              </Box>
            </Box>
          )}
        </div>
      )}
    </Downshift>
  );
};

export default Autocomplete;
