import { USER_ROLES_OPTIONS } from "../../common/constants";

export const convertEmployeeToApi = (employee) => ({
  ...employee,
  email: employee.email.trim(),
  name: employee.name.trim(),
  lastName: employee.lastName.trim(),
  role: employee.role.value,
  password: employee.passwords?.password,
  warehouseIds: employee.warehouses?.map((city) => city.id) || null,
  cityIds: employee.cities?.map((city) => city.id) || [],
  ...(employee?.warehouses?.length > 0 && { warehouseId: employee.warehouses[0].id }),
});

export const convertEmployeeFromApi = (employeeDetails) => ({
  ...employeeDetails,
  warehouses:
    employeeDetails.warehouses?.map((warehouse) => {
      return { name: warehouse.city, id: warehouse.id };
    }) || [],
  role: USER_ROLES_OPTIONS.find((userRole) => userRole.value === employeeDetails.role),
});
